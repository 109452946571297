/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@font-face{
    font-weight:400;
    font-style:normal;
    src:url('./assets/fonts/sofia_reg.woff2') format("woff2"),url("./assets/fonts/sofia_reg.woff") format("woff");
    font-display:swap;
    font-family:sofia
  }
  @font-face{
    src:url("./assets/fonts/sofia_reg_subset.woff2") format("woff2"),url("./assets/fonts/sofia_reg_subset.woff") format("woff");
    font-weight:400;
    font-style:normal;
    font-display:swap;
    font-family:sofia
  }
  @font-face{
    font-weight:700;
    font-style:normal;
    src:url("./assets/fonts/sofia_bold.woff2") format("woff2"),url("./assets/fonts/sofia_bold.woff") format("woff");
    font-display:swap;
    font-family:sofia
  }
  @font-face{
    src:url("./assets/fonts/sofia_bold_subset.woff2") format("woff2"),url("./assets/fonts/sofia_bold_subset.woff") format("woff");
    font-weight:700;
    font-style:normal;
    font-display:swap;
    font-family:sofia
  }
  
  @font-face{
    src:local(Arial);
    ascent-override:109.08203%;
    descent-override:27.05078%;
    line-gap-override:0;
    font-family:sofiaFallback
  }

  /* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #525252;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #525252;
  border-radius: 20px;
  border: 1px solid whitesmoke;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

// ngb-carousel .wrapper {
//   position: relative;
//   width: 100%;
// }

// ngb-carousel .wrapper>img {
//   width: 100%;
//   height: auto;
// }

ngb-carousel .wrapper {
  position: relative;
  height: 0;
  padding-top: 41.67%; /* Keep ratio for 1440x400 images */
}

ngb-carousel .wrapper>img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

html, body {
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
}

button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

button:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

body {
  display: grid;
  grid-template-rows: 1fr auto; /* One row for the main content, one for the footer */
}

main {
  overflow-y: auto; /* Enable vertical scroll if content exceeds viewport height */
}

footer {
  margin-top: auto; /* Push the footer to the bottom */
}